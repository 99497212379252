/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import goconut from './assets/goconut.svg'
import blatt from './assets/goconut_Palmeblatt.webp'

const indexStyles = {
    heroContainer: {
        position: `relative`,
        pt: 5,
        '::before': {
            content: `" "`,
            width: `100%`,
            height: `1000px`,
            position: `absolute`,
            top: `-42%`,
            right: [0, null, null, `11%`],
            transform: [`none`, null, null, `skew(356deg, 355deg) rotate(5deg)`],
            zIndex: -1,
            borderRadius: `xl`,
            background: t => `${t.colors.beta}`,
            backgroundSize: `100%`,
            backgroundPosition: `650px bottom`
        },
        '::after': {
            content: `" "`,
            position: `absolute`,
            height: '250% !important',
            top: `-15%`,
            left: `-55%`,
            transform: `scale(2)`,
            zIndex: -1,
            size: 'full',
            // maxWidth: 600,
            // maxHeight: 600,
            background: `url(${blatt}) no-repeat center center`,
            backgroundSize: `contain`,
            opacity: 0.3
        }
    },
    featuresContainer: {
        position: `relative`,
        overflow: [`hidden`, `hidden`],
        '::before': {
            content: `" "`,
            width: `100%`,
            position: `absolute`,
            top: [`5%`, null, `5%`],
            height: [`80%`, null, `100%`],
            right: [0, null, null, `-70%`],
            transform: `skew(10deg, 4deg) rotate(-10deg)`,
            zIndex: -1,
            borderRadius: `xl`,
            background: t => `${t.colors.alpha}`,
            backgroundSize: `100%`,
            backgroundPosition: `650px bottom`
        },
        '::after': {
            display: [`none`, `block`],
            content: `" "`,
            position: `absolute`,
            top: `5%`,
            left: `80%`,
            transform: `translate(-50%, 0)`,
            zIndex: -1,
            size: '500px',
            background: `url(${goconut}) no-repeat center center`,
            backgroundSize: `contain`,
            // filter: `brightness(60%) sepia(100) saturate(100) hue-rotate(25deg)`,
            opacity: 0.2
        }
    },
    socialProofContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            width: `100%`,
            height: `100%`,
            position: `absolute`,
            top: 0,
            left: 0,
            zIndex: -2,
            bg: `#F6F7FA`
        }
    },
    buildBrandContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            right: 0,
            top: `13%`,
            zIndex: -1,

            opacity: 0.3,
            backgroundSize: `40%`
        }
    },
}

export default indexStyles
